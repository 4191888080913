<div class="header-container py-3">
  <header class="container align-items-center">
    <div class="row align-items-center">
      <div class="col-xl-4 logo d-flex align-items-center justify-content-center cursor-pointer" routerLink="/">
        <div class="image">
          <img alt="logo" src="assets/images/layout/logo.png" />
        </div>

        <div class="pl-3">
          <p class="mb-0">Vastgoedbeleggingen</p>

          <p class="mb-0">CRM</p>
        </div>
      </div>

      <div class="col-7 col-md-9 col-xl-6 my-xl-0 my-3" (capClickOutside)="hideSearchResults()">
        <form class="d-sm-flex search-form" [formGroup]="form">
          <div class="flex-sm-grow-1">
            <cap-input
              formControlName="search"
              iconLeft="fas fa-search"
              [placeholder]="'header.search.placeholder' | translate"
              (click)="showSearchResults()">
            </cap-input>
          </div>

          <div class="d-flex justify-content-center">
            <cap-button
              styleClass="primary"
              [label]="('header.search.placeholder' | translate).replaceAll('.', '')"
              (click)="onSearch()">
            </cap-button>
          </div>
        </form>

        @if (searchResultsVisible && (searchResults || searching || noResults)) {
          <div class="dropdown-list-container">
            <div class="dropdown-list">
              <div class="dropdown-list-item">
                <ul>
                  @if (searchResults && searchResults?.length > 0 && !searching) {
                    @for (result of searchResults; track result) {
                      <li>
                        <div
                          class="row search-result align-items-center"
                          [routerLink]="['/', routes.admin, routes.clients, result.id]"
                          (click)="hideSearchResults()">
                          <div class="col">
                            <span class="d-block" [innerHTML]="result.full_name"> </span>
                            @for (found of result.found_in; track found) {
                              <span class="text-primary d-block" [innerHTML]="found"> </span>
                            }
                          </div>
                          <div class="col-1">
                            <div class="float-right">
                              <i class="fas fa-chevron-right"></i>
                            </div>
                          </div>
                        </div>
                      </li>
                    }
                  }
                  @if (searching) {
                    <div class="text-primary loading-indicator">
                      <strong>{{ 'search.loading.label' | translate }}</strong>
                    </div>
                  }
                  @if (noResults && !searching) {
                    <div class="text-primary no-results">
                      <strong>{{ noResultsLabel }}</strong>
                    </div>
                  }
                </ul>
              </div>
            </div>
          </div>
        }
      </div>

      <div class="col-5 col-md-3 col-xl-2 text-center text-sm-left d-sm-flex">
        <cap-button class="my-1 mr-2" icon="fas fa-fw fa-history" styleClass="secondary" (click)="toggleSidebar()">
        </cap-button>

        <cap-button
          class="my-1 mr-2"
          icon="fas fa-fw fa-user"
          styleClass="secondary"
          (click)="profileMenu.toggle($event)">
        </cap-button>

        <cap-button
          class="my-1 mr-2 btn-xs"
          icon="fas fa-fw fa-cogs"
          styleClass="secondary"
          [routerLink]="['/', routes.admin, routes.config]">
        </cap-button>

        <cap-button
          class="my-1 mr-2 btn-xs"
          icon="fas fa-fw fa-phone"
          styleClass="secondary"
          [routerLink]="['/', routes.admin, routes.forms, routes.callForm, routes.add]">
        </cap-button>
      </div>

      <p-menu #profileMenu [model]="profileMenuItems" [popup]="true"></p-menu>
    </div>
  </header>
</div>
